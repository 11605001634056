import React from 'react'

import cn from 'classnames/bind'
import {graphql} from 'gatsby'
import PropTypes from 'prop-types'

import Seo from '@/components/atoms/Seo'
import Blocks from '@/components/molecules/Blocks'
import LastNews from '@/components/organisms/LastNews'
import WordPressProvider from '@/components/providers/WordPressProvider'
import getMenus from '@/functions/wordpress/menus/getMenus'
import LayoutDefault from '@/layouts/LayoutDefault'
import * as styles from '@/styles/templates/Product.module.css'

export default function Product({data}) {
  const menus = getMenus(data?.allWpMenu)
  const blocks = data?.wpProduct?.acfProductBuilder?.acfBlocks
  const themeSettings = data?.wp?.evaluarTheme?.settings
  const productTheme = data?.wpProduct?.acfProductSettings?.theme
  let cx = cn.bind(styles)
  let className = cx({
    product: true,
    themeBlue: productTheme === 'theme-blue',
    themeGreen: productTheme === 'theme-green',
    themePurple: productTheme === 'theme-purple'
  })

  return (
    <WordPressProvider value={{menus, blocks, themeSettings}}>
      <LayoutDefault data={data?.allHubspotPost?.nodes}>
        <Seo
          seoConfig={data?.wp?.seo}
          postTypeSeo={data?.wpProduct?.seo}
          language={data?.wpProduct?.language}
        />
        <div className={cn('relative', className)}>
          <div className={cn('theme', productTheme)}></div>
          <Blocks blocks={blocks} />
          <LastNews className="pt-16 md:pt-24" />
        </div>
      </LayoutDefault>
    </WordPressProvider>
  )
}

Product.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export const query = graphql`
  query GET_PRODUCT_BY_ID($id: String!) {
    wpProduct(id: {eq: $id}) {
      ...WpProductFragment
    }
    wp {
      ...WpFragment
    }
    allWpMenu {
      ...WpMenuConnectionFragment
    }
  }
`
